import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import SuperJSON from "superjson";

import { trpc } from "~/utils/trpc";

import Calendar, { type CalendarProps } from "./Calendar";
import Event, { type EventProps } from "./Event";
import TableOfContents from "./TableOfContents";

type ApiOptions = { apiEndpointUrl: string };
type TableOfContentsWidgetOptions = { apiEndpointUrl: undefined } & {
  type: "tableOfContents";
};
type CalendarWidgetOptions = ApiOptions & CalendarProps & { type: "calendar" };
type EventWidgetOptions = ApiOptions & EventProps & { type: "event" };
export type WidgetOptions =
  | TableOfContentsWidgetOptions
  | CalendarWidgetOptions
  | EventWidgetOptions;

const widgetTypes = {
  tableOfContents: () => <TableOfContents />,
  calendar: (props: CalendarProps) => <Calendar {...props} />,
  event: () => <Event />,
};

const Widget = ({ type, apiEndpointUrl, ...options }: WidgetOptions) => {
  if (!apiEndpointUrl) {
    return widgetTypes[type](options);
  }

  const queryClient = new QueryClient();
  const trpcClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: apiEndpointUrl,
        transformer: SuperJSON,
      }),
    ],
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {Object.hasOwn(widgetTypes, type) && widgetTypes[type](options)}
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default Widget;
