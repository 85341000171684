const items = [
  { name: "Calendar", href: "/calendar/" },
  { name: "Event", href: "/event/" },
];

const TableOfContents = () => {
  return (
    <>
      <h2>Examples</h2>
      <ul>
        {items.map(({ name, href }) => (
          <li key={href}>
            <a href={href}>{name}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default TableOfContents;
